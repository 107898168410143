import React from 'react'
import { t } from '@zupr/i18n'
import { Classification } from '@zupr/types/fo'

import { ClassificationBreadcrumbs } from '../../../components/grid/breadcrumbs'
import LinkChangeQuery from '../../../../shared/router/link-change-query'

interface Props {
    classificationBreadcrumbs: Classification[]
}

const Breadcrumbs = ({ classificationBreadcrumbs }: Props) => (
    <div className="grid-breadcrumbs-path">
        <LinkChangeQuery href="/winkels" query={{ classification: null }}>
            {t('Alle locaties', 'filter')}
        </LinkChangeQuery>

        {!!classificationBreadcrumbs?.length && (
            <React.Fragment>
                {'/'}
                <ClassificationBreadcrumbs
                    baseUrl="/winkels"
                    classifications={classificationBreadcrumbs}
                />
            </React.Fragment>
        )}
    </div>
)

export default Breadcrumbs
