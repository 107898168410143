import classNames from 'classnames'

import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'

import { useIsMobile } from '../../../../context/ux'

import LinkChangePath from '../../../../shared/router/link-change-path'
import Filters, { LocationsFiltersProps } from '../filters'

import PaginationOffset from '../../../../shared/components/pagination/offset'
import PaginationSort from '../../../../shared/components/pagination/sort'

import ListIcon from '../../../../../svg/list.svg'

export const ListAndFilterButton = ({
    locations,
    ...props
}: LocationsFiltersProps) => (
    <div className="grid-list-pagination">
        <LinkChangePath
            className="btn btn-small btn-radius-small"
            href="/winkels"
        >
            <ListIcon />
            <span>{t('Listview')}</span>
        </LinkChangePath>

        <div className="grid-list-sorters">
            <Filters {...props} locations={locations} />
        </div>
    </div>
)

export const PaginationAndFilterButton = ({
    locations,
    ...props
}: LocationsFiltersProps) => (
    <div className="grid-list-pagination">
        <PaginationOffset count={locations.count} />

        <div className="grid-list-sorters">
            <Filters {...props} locations={locations} />
        </div>
    </div>
)

interface SortAndPaginationProps {
    locations: List<Location>
    className?: string
}

const SortAndPagination = ({
    locations,
    className,
}: SortAndPaginationProps) => {
    const isMobile = useIsMobile()
    return (
        <div className={classNames('grid-list-pagination', className)}>
            <PaginationOffset count={locations.count} />
            <div className="grid-list-sorters">
                {!isMobile && (
                    <PaginationSort
                        defaultOrdering="-created"
                        choices={['-created', 'title']}
                    />
                )}
            </div>
        </div>
    )
}
export default SortAndPagination
