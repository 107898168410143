import { gql } from 'urql'

const classificationsQuery = gql`
    query Classifications(
        $offset: Int
        $depth: Int
        $depth_Gt: Int
        $depth_Lt: Int
        $path_Descendants: String
        $slug: String
        $slug_Ancestors: String
        $slug_Descendants: String
        $shoppingAreaSlug: String!
    ) {
        classifications(
            first: 100
            offset: $offset
            depth: $depth
            depth_Gt: $depth_Gt
            depth_Lt: $depth_Lt
            path_Descendants: $path_Descendants
            slug: $slug
            slug_Ancestors: $slug_Ancestors
            slug_Descendants: $slug_Descendants
            shoppingAreaSlug: $shoppingAreaSlug
        ) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    slug
                    path
                    name
                    nameSingular
                    depth
                }
            }
        }
    }
`

export default classificationsQuery
