import React, { useContext, useCallback } from 'react'
import { useRouter } from 'next/router'

import SearchBox from '../../../../shared/components/searchbox'

import RouteContext from '../../../../context/route'
import UxContext from '../../../../context/ux'

const LocationListSearch = () => {
    const { push } = useRouter()
    const { changeQuery } = useContext(RouteContext)
    const { search, setSearch } = useContext(UxContext)

    const handleChange = useCallback(
        (search) => {
            setSearch(search)
            push(
                changeQuery({
                    search,
                })
            )
        },
        [changeQuery, push, setSearch]
    )

    return <SearchBox value={search} onChange={handleChange} />
}

export default LocationListSearch
