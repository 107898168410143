import classificationsQuery from '@zupr/queries/classifications'
import { DomainConfig } from '@zupr/types/fo'
import {
    ClassificationType,
    QueryClassificationsArgs,
} from '@zupr/types/graphql'
import { Req } from '@zupr/types/next'

import doQuery from './graphql'

const fetchPages = async (
    variables: QueryClassificationsArgs,
    req: Req
) => {
    const result = await doQuery<
        QueryClassificationsArgs
    >({
        query: classificationsQuery,
        variables
    }, req)
    return result.data.classifications
}

export const getClassifications = async (
    shoppingAreaSlug: DomainConfig['slug'],
    req: Req
): Promise<ClassificationType[]> => {
    let knownPages = 2
    let classifications = []

    // fetch the know pages pages
    const pages = await Promise.all(
        [...Array(knownPages)].map((_, page) =>
            fetchPages({
                shoppingAreaSlug: shoppingAreaSlug || 'nederland',
                offset: page * 100,
            }, req)
        )
    )

    pages.map((page) => {
        const data = page.edges.map(({ node }) => node)
        classifications = [...classifications, ...data]
    })

    const [lastPage] = pages.slice(-1)

    while (true) {
        if (!lastPage.pageInfo.hasNextPage) break

        // if last page has no more nextPage break immediataly
        const result = await fetchPages({
            shoppingAreaSlug: shoppingAreaSlug || 'nederland',
            offset: knownPages * 100,
        }, req)

        const data = result.edges.map(({ node }) => node)
        classifications = [...classifications, ...data]

        knownPages = knownPages + 1

        if (!(result && result.pageInfo && result.pageInfo.hasNextPage)) break
    }

    return classifications
}

export const getFullpathForSlug = ({ slug, classifications }) => {
    // find classification for the slug
    const leaf = classifications.find((classification) => {
        return classification.slug === slug
    })

    if (!leaf) return null

    // add all children based on path
    return leaf.path.split('.').map((path) =>
        classifications.find((classification) => {
            return classification.slug === path
        })
    )
}

export const getFilteredClassifications = async ({ slug, variables }, req: Req) => {
    let classifications = await getClassifications(slug, req)

    // filter out classification based on depth
    if (variables.depth) {
        classifications = classifications.filter((classification) => {
            return classification.depth <= variables.depth
        })
    }

    return classifications || []
}
