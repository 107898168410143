import { Classification } from '@zupr/types/next'

import FilterCollapse from '../../../../shared/filters/collapse'
import RemoveFilter from '../../../../shared/filters/remove'
import LinkChangeQuery from '../../../../shared/router/link-change-query'
import { FilterLink } from '../../../components/search/filter/link'

import ChevronIcon from '../../../../../svg/chevron.svg'

interface RemoveClassificationsProps {
    classifications?: Classification[]
    baseUrl?: string
}

export const RemoveClassifications = ({
    baseUrl,
    classifications,
}: RemoveClassificationsProps) => {
    if (!classifications?.length) return null
    return (
        <>
            {classifications.map((classification) => (
                <RemoveFilter
                    href={baseUrl}
                    filterKey="classification"
                    removeFromValue={classification.slug}
                    value={classification.path.split('.').slice(-2).join(',')}
                >
                    {classification.name}
                </RemoveFilter>
            ))}
        </>
    )
}

interface Props {
    classificationBreadcrumbs: Classification[]
    classifications: Classification[]
    activeClassification: Classification
}

const LocationClassifications = ({
    classifications,
    activeClassification,
    classificationBreadcrumbs,
}: Props) => {
    // get the parent classification
    const parent =
        classificationBreadcrumbs.length > 1 &&
        classificationBreadcrumbs[classificationBreadcrumbs.length - 2]

    return (
        <FilterCollapse header="Categories">
            {activeClassification && (
                <LinkChangeQuery
                    query={{
                        classification: parent?.slug || null,
                    }}
                    className="filter-back"
                >
                    <ChevronIcon />
                    <span>{activeClassification.name}</span>
                </LinkChangeQuery>
            )}

            {classifications.map(({ slug, name, locationCount }) => (
                <FilterLink
                    key={slug}
                    query={{ classification: slug }}
                    count={locationCount}
                >
                    {name}
                </FilterLink>
            ))}
        </FilterCollapse>
    )
}

export default LocationClassifications
