import classnames from 'classnames'
import React, { useCallback } from 'react'

import LocateIcon from '../../../svg/locate.svg'
import { useShopperLocation } from '../../context/shopper'
import { Loader } from './loader'

import '../../../scss/react/map/user-location.scss'

interface Props {
    className?: string
    large?: boolean
    children?: React.ReactNode
}

export const PositionButton = ({ className, large, children }: Props) => {
    const { declined, unavailable, place, position, locating, getPosition } =
        useShopperLocation()

    const handleDeterminePosition = useCallback(
        (ev) => {
            ev.preventDefault()

            // when user has declined geolocation and we have no place
            // restart the page
            if (!place && declined) {
                return global.location.reload()
            }
            getPosition()
        },
        [declined, getPosition, place]
    )

    // No functionality for this button if we have no place
    if (!place && unavailable) return null

    return (
        <button
            type="button"
            className={classnames(className, {
                'btn-small': !large,
                locating,
                'is-located': place || position,
            })}
            onClick={handleDeterminePosition}
        >
            {!locating && <LocateIcon />}
            {children}
        </button>
    )
}

const MapsLoader = (props: Props) => (
    <Loader renderLoading={<PositionButton />}>
        <PositionButton {...props} />
    </Loader>
)

export default MapsLoader
