import {
    Filter,
    FilterKeys,
    Location
} from '@zupr/types/fo'
import { ClassificationType } from '@zupr/types/graphql'
import { Domain, LocationsAndAggregations, Req, SiteProps } from '@zupr/types/next'

import { getClassifications, getFullpathForSlug } from './classifications'
import { getAggregations, getList } from './fo'
import { searchFilters } from './search'

export const aggregationKeys = {
    brand: 'brand.id',
    city: 'city',
}

// these are the filters that are counted
// the keys are used in query string and are "clean" version of the values
export const filterKeys: FilterKeys = {
    city: 'city',
    brands: 'brand.id__in',
    open: 'opening_hours.day',
    closed: 'opening_hours.day__not',
    delivery: 'can_do_delivery',
    collect: 'allow_pay_and_collect',
    reservation: 'allow_reservation',
    box: 'geo_location__bounding_box',
    ordering: 'ordering',
    location: null, // remove from query
}

interface GetLocationClassificationsProps {
    domain?: Domain
    classifications: string[]
}

export const getLocationClassifications = async ({
    domain,
    classifications,
}: GetLocationClassificationsProps, req: Req) => {
    const allClassifications = await getClassifications(domain?.slug, req)

    return classifications.map(
        (slug): ClassificationType =>
            allClassifications.find(
                (classification) => slug === classification.slug
            )
    ).filter((classification) => !!classification) // remove classifications that are not found
}

const getClassificationsFromAggregations = async ({
    activeClassification,
    aggregation,
    params,
    key,
}, req: Req): Promise<ClassificationType[]> => {
    // fetch all classifications
    const classifications = await getClassifications(
        params.shopping_areas as string, 
        req
    )

    // find the classifications that are in the aggregation
    // and return the classifications with the product count
    return classifications
        .filter((category) => {
            return aggregation?.buckets?.find((bucket) => {
                if (activeClassification)
                    return (
                        bucket.key === category.path &&
                        bucket.key.startsWith(activeClassification.path)
                    )
                return bucket.key === category.path
            })
        })
        .map((category) => {
            const bucket = aggregation?.buckets?.find((bucket) => {
                return bucket.key === category.path
            })
            return {
                ...category,
                locationCount:
                    bucket?.[`reverse.${key}`]?.doc_count || bucket?.doc_count,
            }
        })
}

const getActiveClassification = async ({
    query,
    params,
}, req: Req): Promise<ClassificationType | null> => {
    // fetch all classifications
    const classifications = await getClassifications(
        params.shopping_areas as string, 
        req
    )

    // slug of active category
    const classificationSlug = query.classification || params.classification

    // find the category by slug
    const activeClassification =
        !!classificationSlug &&
        classifications.find((classification) => {
            return classification.slug === classificationSlug
        })

    return activeClassification || null
}

const getClassificationBreadcrumbs = async ({
    classification,
    params,
}, req: Req): Promise<ClassificationType[]> => {
    // fetch all classifications
    const classifications = await getClassifications(
        params.shopping_areas as string,
        req
    )
    let classificationBreadcrumbs = []
    if (classification) {
        classificationBreadcrumbs = getFullpathForSlug({
            classifications,
            slug: classification.slug,
        })
    }
    return classificationBreadcrumbs
}

interface GetLocationProps {
    all?: boolean
    params: Record<string, string | number | boolean>
    query: SiteProps['query']
}

export const getLocationsAndAggregations = async ({
    params,
    query,
    all
}: GetLocationProps, req: Req): Promise<LocationsAndAggregations> => {
    const activeClassification = await getActiveClassification({
        query,
        params,
    }, req)

    const { variables, filterCount } = searchFilters({
        filterKeys: {
            ...filterKeys,
            classification: activeClassification
                ? `classifications_${activeClassification?.depth - 1}`
                : null,
        },
        ordering: '-created', // default ordering
        query: {
            limit: 24, // default limit
            ...query,
            ...params,
            classification: activeClassification?.path || null, // change from slug to path
        },
    })

    // special case for negative filters (box)
    let finalFilterCount = filterCount
    if (variables[filterKeys.box] === 'false') {
        delete variables[filterKeys.box]
        finalFilterCount = filterCount - 1
    }

    const filter: Filter = {
        variables,
        filterCount: finalFilterCount,
    }

    const [locations] = await getList<Location>({
        url: 'fo/location',
        variables,
        all,
    }, req)

    const classificationAggregationKey = `classifications_${
        activeClassification?.depth || 0
    }`

    const aggregations = await getAggregations({
        url: 'fo/location',
        variables,
        aggregation: {
            keys: {
                ...aggregationKeys,
                classification: classificationAggregationKey,
            },
        },
    }, req)

    const classifications = await getClassificationsFromAggregations({
        aggregation: aggregations.data?.[classificationAggregationKey],
        activeClassification,
        params,
        key: classificationAggregationKey,
    }, req)

    const classificationBreadcrumbs = await getClassificationBreadcrumbs({
        classification: activeClassification,
        params,
    }, req)

    // add classifications to results
    // get all classifications
    const allClassifications = await getClassifications(
        params.shopping_areas as string, req
    )

    locations.results = locations.results.map((location) => {
        location.classificationList = location.classifications.map((slug) =>
            allClassifications.find(
                (classification) => classification.slug === slug
            )
        ).filter(classification => !!classification) // remove classifications that are not found
        return location
    })

    return {
        filter,
        locations,
        aggregations,
        activeClassification,
        classificationBreadcrumbs,
        classifications,
    }
}
