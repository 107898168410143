import classNames from 'classnames'
import React from 'react'

import '../../../../scss/react/frontoffice/grid-page.scss'

interface GridColumnProps {
    swapOrder?: boolean
    children: React.ReactNode
    small?: boolean
    className?: string
}

const GridColumn = ({
    swapOrder,
    small,
    children,
    className,
}: GridColumnProps) => (
    <div
        className={classNames('grid-column', className, {
            'grid-column-swap-order': swapOrder,
            'grid-column-small': small,
        })}
    >
        {children}
    </div>
)

export default GridColumn
