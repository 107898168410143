import { t } from '@zupr/i18n'
import React from 'react'

import '../../../../../scss/react/backoffice/view-map.scss'
import LinkChangePath from '../../../../shared/router/link-change-path'

const ViewMap = ({ mapView }) => (
    <React.Fragment>
        {!mapView && (
            <div className="view-map">
                <LinkChangePath className="btn btn-small" href="/winkels/kaart">
                    {t('Toon kaartweergave', 'dutch')}
                </LinkChangePath>
            </div>
        )}

        {!!mapView && (
            <LinkChangePath
                className="btn btn-small btn-full-width"
                href="/winkels"
            >
                {t('Toon lijst', 'dutch')}
            </LinkChangePath>
        )}
    </React.Fragment>
)

export default ViewMap
